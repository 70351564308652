import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { getResponseMessage } from '../responding';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AttachFile, Close, MessageOutlined, NotificationAddOutlined } from '@mui/icons-material';
import colors from '../../colors';

const label = { inputProps: { 'aria-label': 'Switch demo' } };


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`
const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    //min-height: 320px;
    min-height: calc(100vh - 115px);
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 25px 5%;
    background-color: white;
    min-height: 80%;
    height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
`

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
`

const HeaderLink = styled(Link)`
    cursor: pointer;
    color: white;
`

export default function HomeSend({djezzyCosts, mobilisCosts, ooredooCosts, Language}) {

    // mui config
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const openPop = Boolean(anchorEl);


    const [openUserModal, setOpenUserModal] = React.useState(false);

    const handleClickOpenUserModal = () => {
        setOpenUserModal(true);
    };

    const handleCloseUserModal = () => {
        setOpenUserModal(false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpenPragrammeMessageModal = () => {
        setOpen(true);
    };

    const handleClosePragrammeMessageModal = () => {
        setOpen(false);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpenAlert(false);
    };


    // end mui config

    const navigate = useNavigate()

    const [contacts, setContacts] = useState([]);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [time, setTime] = useState('');
    const [repeted, setRepeted] = useState('');
    const [hour, setHour] = useState('0');

    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [isErrorSubject, setIsErrorSubject] = useState(false);
    const [isErrorContacts, setIsErrorContacts] = useState(false);
    const [isErrorTime, setIsErrorTime] = useState(false);
    const [isErrorRepeted, setIsErrorRepeted] = useState(false);
    const [isErrorHour, setIsErrorHour] = useState(false);


    const [contactsError, setContactsError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [repetedError, setRepetedError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [hourError, setHourError] = useState('');


  const [ add_group, set_add_group] = useState('')
  const [ add_first_name, set_add_first_name] = useState('')
  const [ add_family_name, set_add_family_name] = useState('')
  const [ add_phone_number, set_add_phone_number] = useState('')
  const [ add_email, set_add_email] = useState('')
  const [ add_society, set_add_society] = useState('')

  const [ is_error_group, set_is_error_group] = useState(false)
  const [ is_error_first_name, set_is_error_first_name] = useState(false)
  const [ is_error_family_name, set_is_error_family_name] = useState(false)
  const [ is_error_email, set_is_error_email] = useState(false)
  const [ is_error_phone_number, set_is_error_phone_number] = useState(false)
  const [ is_error_society, set_is_error_society] = useState(false)

  const [ group_error, set_group_error] = useState(false)
  const [ first_name_error, set_first_name_error] = useState(false)
  const [ family_name_error, set_family_name_error] = useState(false)
  const [ email_error, set_email_error] = useState(false)
  const [ phone_number_error, set_phone_number_error] = useState(false)
  const [ society_error, set_society_error] = useState(false)
  
  const [ create_contact_btn_content, set_create_contact_btn_content] = useState('create')
  const [disabledCreateContact, setDisabledCreateContact] = useState(false);

    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)

    const [sendMessageBtnContent, setsendMessageBtnContent] = useState(<SendIcon  style={{fontSize: "large", marginLeft: '10px'}}/>);
    const [programmeMessageBtnContent, setprogrammeMessageBtnContent] = useState(<AccessAlarmsIcon  style={{fontSize: "large", marginLeft: '10px'}}/>);

    const [confirmProgrammeMessageBtn, setconfirmProgrammeMessageBtn] = useState('programme');

    const [contactList, setContactsList] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showByGroup, setShowByGroup] = useState(false);
    const [selectContactsOptions, setSelectContactsOptions] = useState([]);

    const [ableToSend, setableToSend] = useState(false);

    const [disabledBtnSend, setDisabledBtnSend] = useState(false);
    const [disabledBtnSchedule, setDisabledBtnSchedule] = useState(false);
    const [disabledBtnSaveSchedule, setDisabledBtnSaveSchedule] = useState(false);
    const [senders, setSenders] = useState([]);
    const [messageCalc, setMessageCalc] = useState('0/160');
    const [messageLength, setMessageLength] = useState('0');


    function create_Contact(first_name, family_name, email, phone_number, society, group, showByGroup){
      setDisabledCreateContact(true)
      set_create_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      console.log(first_name, family_name, email, phone_number, society)
      // get data from database
      axios.post("/create_contact", {
        first_name, family_name, email, phone_number, society, group, showByGroup
      })
      .then(function (response) {
        setDisabledCreateContact(false)
        set_create_contact_btn_content("create")
        //console.log(response.data)
        console.log(contacts)
        console.log(response.data.newItem)
        if(response.data.res === 'done'){
          set_add_first_name('')
          set_add_family_name('')
          set_add_email('')
          set_add_phone_number('')
          set_add_society('')
          setLoading(false)
          if(showByGroup){
            setSelectContactsOptions(response.data.groups)
          }else{
            setSelectContactsOptions(response.data.contacts)
          }
          setGroups(response.data.groups)
          setContactsList(response.data.contacts)
          handleCloseUserModal()
          setAlertMessage('Item added successfuly')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // show data
        }
        else if(response.data.res === 'server_error'){
          setLoading(false)
          setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // redirect to server error page
        }
        else if(response.data.res === 'input_error'){
          setLoading(false)
          // redirect to server error page
          for( let i = 0; i < response.data.error.length; i++){
            console.log(response.data.error[i])
            if(response.data.error[i].path === 'first_name'){
              set_first_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_first_name(true)
            }
            else if(response.data.error[i].path === 'group'){
              set_group_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_group(true)
            } 
            else if(response.data.error[i].path === 'family_name'){
              set_family_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_family_name(true)
            }    
            else if(response.data.error[i].path === 'email'){
              set_email_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_email(true)
            } 
            else if(response.data.error[i].path === 'phone_number'){
              set_phone_number_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_phone_number(true)
            } else if(response.data.error[i].path === 'society'){
              set_society_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_society(true)
            } 

          }
        }
        else if(response.data.res === 'redirect'){
          setLoading(false)
          //navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        setDisabledCreateContact(false)
        // handle error
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpenAlert(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")
          }

        }else{
          setOpenAlert(true)
          setAlertMessage('Internal server error')
          setAlertType("error")
        }
      })

      //handleCloseUserModal()
    }


    function getOptionLabel(isByGroup, option){
      if(isByGroup){
        var contact = 0
        if(option.contacts){
          contact = option.contacts.length
        }
        return option.title + ' (' + contact + ')'
      }else{
        return option.first_name + ' ' + option.family_name + ' - ' + option.phone_number + ' - ' + option.society
      }
    }

    const [messagesCount, setMessagesCount] = useState(0);

    useEffect(() => {
      setContacts([])
      if(showByGroup){
        setSelectContactsOptions(groups)
      }else{
        setSelectContactsOptions(contactList)
      }
    }, [showByGroup]);

    const [price, setPrice] = useState(0);

    useEffect(() => {
      var numbers = []
      var price = 0
      if(showByGroup){
        for (let i = 0; i < contacts.length; i++) {
          const element = contacts[i].contacts;
          for (let j = 0; j < element.length; j++) {
            const item = element[j];
            numbers.push(item.phone_number)
          }

        }
      }else{
        for (let j = 0; j < contacts.length; j++) {
          const item = contacts[j];
          numbers.push(item.phone_number)
        }
      }


      for (let i = 0; i < numbers.length; i++) {
        const item = numbers[i];
        console.log(djezzyCost)
        //console.log(item[4] === '5')
        if(item[4] === "7"){
          price =  parseFloat(price) +   parseFloat(djezzyCost)
        }else if(item[4] === "6"){
          price =  parseFloat(price) +   parseFloat(mobilisCost)
        }else if (item[4] === "5"){
          price =  parseFloat(price) +   parseFloat(ooredooCost)
        }
      }
      setPrice(parseFloat(price) * messagesCount)
    }, [contacts, messagesCount]);



    function sendMessage(subject, contacts, message, type, byGroup){
      console.log(subject, contacts, message)
      setIsErrorContacts(false)
      setIsErrorMessage(false)
      setIsErrorSubject(false)
      setMessageError('')
      setSubjectError('')
      setContactsError('')
      setsendMessageBtnContent(<CircularProgress style={{ width: '15px', height:'15px', color: "white",  marginLeft: '10px'}}></CircularProgress>)
      setDisabledBtnSend(true)
      var readyContacts = contacts
      if(byGroup){
        readyContacts = []
        for (let i = 0; i < contacts.length; i++) {
          const group = contacts[i];
          group.contacts = []
          readyContacts.push(group)
        }
      }
      axios.post("/sendMessage", {
        subject, contacts: readyContacts, message, type, byGroup, 
      })
      .then(function (response) {   
        setsendMessageBtnContent(<AccessAlarmsIcon  style={{fontSize: "large", marginLeft: '10px'}}/>)
        setDisabledBtnSend(false)
        console.log(response.data)
        if(response.data.res === 'done'){
              setMessage('')
              // setAlertMessage( 'Sending now! For updates, visit reporting page.')
              setAlertMessage(<span>{Language['Sending now! For updates, visit']} <span style={{borderBottom: '1px solid', cursor: 'pointer'}} onClick={()=> navigate('/app/SMSMarketing/reporting')}>{Language['reporting page']}</span>.</span>)
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
              navigate('/app/SMSMarketing/reporting')
          // show data
        }
        else if(response.data.res === 'server_error'){
              setAlertMessage(response.data.error)
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
        }
        else if(response.data.res === 'user_error'){
          setAlertMessage(response.data.error)
          setAlertType('error')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
          // redirect to server error page
         }
        else if(response.data.res === 'input_error'){
          // redirect to server error page
          for( let i = 0; i < response.data.error.length; i++){
            console.log(response.data.error[i])
            if(response.data.error[i].path === 'contacts'){
              setContactsError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorContacts(true)
            }
            else if(response.data.error[i].path === 'message'){
              setMessageError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorMessage(true)
            }
            else if(response.data.error[i].path === 'subject'){
              console.log('wow')
              setSubjectError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorSubject(true)
            }
          }
        }
        else if(response.data.res === 'redirect'){
          navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        setDisabledBtnSend(false)
        // handle error
        setsendMessageBtnContent(<SendIcon  style={{fontSize: "large", marginLeft: '10px', color: 'white'}}/>)
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpenAlert(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")            
          }

        }else{
          setOpenAlert(true)
          setAlertMessage('An unexpected error has occured')
          setAlertType("error")
        }
      })
    }


    function pre_programmeMessage(subject, contacts, message, type){
      console.log(subject, contacts, message)
      setIsErrorContacts(false)
      setIsErrorMessage(false)
      setIsErrorSubject(false)
      setMessageError('')
      setSubjectError('')
      setContactsError('')
      setprogrammeMessageBtnContent(<CircularProgress style={{ width: '15px', height:'15px', color: "white",  marginLeft: '10px'}}></CircularProgress>)
      setDisabledBtnSchedule(true)
      var readyContacts = contacts
      if(showByGroup){
        readyContacts = []
        for (let i = 0; i < contacts.length; i++) {
          const group = contacts[i];
          group.contacts = []
          readyContacts.push(group)
        }
      }
      axios.post("/sendMessage", {
        subject, contacts: readyContacts, message, type
      })
      .then(function (response) {
        setprogrammeMessageBtnContent(<AccessAlarmsIcon  style={{fontSize: "large", marginLeft: '10px'}}/>)
        setDisabledBtnSchedule(false)
        console.log(response.data)
        if(response.data.res === 'done'){
              handleClickOpenPragrammeMessageModal()
        }
        else if(response.data.res === 'server_error'){
              setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // redirect to server error page
        }else if(response.data.res === 'user_error'){
          setAlertMessage(response.data.error)
          setAlertType('error')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
        }
        else if(response.data.res === 'input_error'){
          // redirect to server error page
          for( let i = 0; i < response.data.error.length; i++){
            console.log(response.data.error[i])
            if(response.data.error[i].path === 'contacts'){
              setContactsError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorContacts(true)
            }
            else if(response.data.error[i].path === 'message'){
              setMessageError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorMessage(true)
            }
            else if(response.data.error[i].path === 'subject'){
              setSubjectError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorSubject(true)
            }
          }
        }
        else if(response.data.res === 'redirect'){
          navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        // handle error
        setsendMessageBtnContent(<SendIcon  style={{fontSize: "large", marginLeft: '10px', color: 'white'}}/>)
        setDisabledBtnSchedule(false)
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpenAlert(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")            
          }
          
        }else{
          setOpenAlert(true)
          setAlertMessage('An unexpected error has occured')
          setAlertType("error")
        }
      })
    }

    function programmeMessage(subject, contacts, message, time, repeted, hour, byGroup){
      console.log(subject, contacts, message)
      setIsErrorTime(false)
      setIsErrorRepeted(false)
      setTimeError('')
      setRepetedError('')
      setconfirmProgrammeMessageBtn(<CircularProgress style={{ width: '15px', height:'15px', color: "white",  marginLeft: '10px'}}></CircularProgress>)
      setDisabledBtnSaveSchedule(true)
      var readyContacts = contacts
      if(byGroup){
        readyContacts = []
        for (let i = 0; i < contacts.length; i++) {
          const group = contacts[i];
          group.contacts = []
          readyContacts.push(group)
        }
      }

      axios.post("/programmeMessage", {
        subject, contacts: readyContacts, message, time, repeted, hour, byGroup,  type: 'programme'
      })
      .then(function (response) {
        setconfirmProgrammeMessageBtn('programme')
        setDisabledBtnSaveSchedule(false)
        console.log(response.data)
        if(response.data.res === 'done'){
              setMessage('')
              handleClosePragrammeMessageModal()
              // setAlertMessage( response.data.programmed + ' of '+ response.data.total + 'message added successfuly to your Message scheduling list.')
              setAlertMessage('This message added successfuly to your Message scheduling list')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 5000);
        }else if(response.data.res === 'user_error'){
          setAlertMessage(response.data.error)
          setAlertType('error')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
        }
        else if(response.data.res === 'server_error'){
              setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // redirect to server error page
        }
        else if(response.data.res === 'input_error'){
          // redirect to server error page
          for( let i = 0; i < response.data.error.length; i++){
            console.log(response.data.error[i])
            if(response.data.error[i].path === 'contacts'){
              setContactsError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorContacts(true)
              handleClosePragrammeMessageModal()
            }
            else if(response.data.error[i].path === 'message'){
              setMessageError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorMessage(true)
              handleClosePragrammeMessageModal()
            }
            else if(response.data.error[i].path === 'subject'){
              console.log('wow')
              setSubjectError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorSubject(true)
              handleClosePragrammeMessageModal()
            }
            else if(response.data.error[i].path === 'date'){
              setTimeError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorTime(true)
            }
            else if(response.data.error[i].path === 'hour'){
              setHourError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorHour(true)
            }
            else if(response.data.error[i].path === 'repeted'){
              setRepetedError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              setIsErrorRepeted(true)
            }
          }
        }
        else if(response.data.res === 'redirect'){
          navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        // handle error
        setsendMessageBtnContent(<SendIcon  style={{fontSize: "large", marginLeft: '10px', color: 'white'}}/>)
        setDisabledBtnSaveSchedule(false)
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpenAlert(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")
          }

        }else{
          setOpenAlert(true)
          setAlertMessage('An unexpected error has occured')
          setAlertType("error")
        }
      })
    }

    const [oneMessageLength, setOneMessageLength] = useState(160);

    function calcMessage({length, ar}){
      var oneMessageLength = 0
      if(ar){
        if(length>70){
          oneMessageLength = 67
        }else{
          oneMessageLength = 70
        }
      }else{
        if(length> 160){
          oneMessageLength = 153
        }else{
          oneMessageLength = 160
        }
      }
      const message  = (length / oneMessageLength) + 0.999999999
      if(message == 1){
        setMessagesCount(0)
      }else{
        setMessagesCount(Math.floor(message))
      }

      if(length % oneMessageLength === 0){
        setMessageCalc(Math.floor(message) + '/' + 0 )
      }else{
        var left  = oneMessageLength - (length % oneMessageLength)
        setMessageCalc(Math.floor(message) + '/' + left )
      }
      setMessageLength(length)
    }

    const [arabicMessage, setArabicMessage] = useState('');

    function MessagePricing(text, e){

      const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
      const englishAlphanumericRegex = /^[A-Za-z0-9@£$¥èéùìòÇØøÅå \n\r!"#%&'()*+,\-./:;<=>?¡¿|^€{}[\]~\\]*$/;

      if (arabicRegex.test(text)) {
        setOneMessageLength(70)
        setArabicMessage(
        <>
          <Button 
            variant='outlined'
            size='sm'
            style={{color: '#d669ef', border: 'none'}}
            aria-owns={openPop ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            startIcon={<InfoOutlinedIcon></InfoOutlinedIcon>}
          >
            عربي AR
          </Button>
        </>)
        calcMessage({length: text.length, ar: true})
      }else if (!englishAlphanumericRegex.test(text)) {
        setOneMessageLength(70)
        setArabicMessage(
        <>
          <Button 
            variant='outlined'
            size='sm'
            style={{color: '#d669ef', border: 'none'}}
            aria-owns={openPop ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            startIcon={<InfoOutlinedIcon></InfoOutlinedIcon>}
          >
            Special characters
          </Button>
        </>)
        calcMessage({length: text.length, ar: true})
      } else {
        setArabicMessage()
        setOneMessageLength(160)
        calcMessage({length: text.length, ar: false})
      }
    }

    useEffect(() => {
      console.log(oneMessageLength, messageCalc, messageLength, message)
    }, [oneMessageLength, messageCalc, messageLength, message]);


    const [ooredooCost, setOoredooCost] = useState(0);
    const [djezzyCost, setDjezzyCost] = useState(0);
    const [mobilisCost, setMobilisCost] = useState(0);

    useEffect(() => {
      // get data from database
      axios.post("/contact")
      .then(function (response) {
        console.log(response.data)
        if(response.data.res === 'done'){
          setLoading(false)
          setContactsList(response.data.contacts)
          setSelectContactsOptions(response.data.contacts)
          setGroups(response.data.groups)
          setableToSend(response.data.ableToSend)
          setSenders(response.data.senders)
          // show data
          axios.post('/operators')
          .then(function (myResponse){
            if(response.data.res === 'done'){
              console.log(myResponse.data.operators)
              for (let i = 0; i < myResponse.data.operators.length; i++) {
                const item = myResponse.data.operators[i];
                console.log(item.name)
                if(item.name === 'ooredoo'){
                    setOoredooCost(item.price)
                }else if(item.name === 'mobilis'){
                    setMobilisCost(item.price)
                }else if(item.name === 'djezzy'){
                    setDjezzyCost(item.price)
                }
              }
            }else{
                setOoredooCost(0)
                setMobilisCost(0)
                setDjezzyCost(0)
            }
          })
        }
        else if(response.data.res === 'server_error'){
          setLoading(false)
          // redirect to server error page
        }
        else if(response.data.res === 'redirect'){
          setLoading(false)
          navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        // handle error
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpen(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")            
          }

        }else{
          setOpen(true)
          setAlertMessage('An unexpected error has occured')
          setAlertType("error")
        }
      })

    }, [])

    const [afterBestLength, setAfterBestLength] = useState('');
    useEffect(() => {
      if(message.length > 320){
        setAfterBestLength(Language['For a better experience, keep the message under 320 characters.'] ? Language['For a better experience, keep the message under 320 characters.'] : 'For a better experience, keep the message under 320 characters.')
      }else{
        setAfterBestLength('')
      }
    }, [message]);

    const [uploadedFiles, setUploadedFiles] = useState([]);

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['SMS Marketing']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>

            <Form>
            <Collapse in={ableToSend}>
              <Alert
                severity="warning" variant='filled'
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setableToSend(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                  {Language['Unable to send message before setting your SMS account']}  — <HeaderLink to={'config'}>{Language['set my SMS Account!']} </HeaderLink>
              </Alert>
            </Collapse>

                <FormControl error={isErrorSubject} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '30px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Sender']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    //value={'age'}
                    onChange={ (e) =>{
                      setSubject(e.target.value)
                      setIsErrorSubject(false)
                      setSubjectError('')
                    }
                    }
                    >

                    {
                      senders.map(((item) => {
                        return (
                          <MenuItem value={item.source}>{item.source}</MenuItem>
                        )
                      }))
                    }
                    </Select>
                    <FormHelperText id="time_error">{subjectError}</FormHelperText>
                </FormControl>

                <FormControl error={isErrorContacts} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                    <Autocomplete
                    style={{width: '100%', marginTop: '20px'}}
                    multiple
                    noOptionsText={
                      [''].map(() => {
                        if(showByGroup){
                          return(
                            <><p>{Language['no option']}</p> <Button variant='contained' onClick={handleClickOpenUserModal}>{Language['Create Group']}</Button></>
                          )
                        }else{
                          return(
                            <><p>{Language['no option']}</p> <Button variant='contained' onClick={handleClickOpenUserModal}>{Language['Create Contact']}</Button></>
                          )
                        }
                       
                      })
                    }
                    variant="standard"
                    id="tags-outlined"
                    value={contacts}
                    options={selectContactsOptions}
                    getOptionLabel={(options) =>  getOptionLabel(showByGroup, options) }
                    filterSelectedOptions
                    onChange={(event, value) => {
                      setContacts(value)
                      setIsErrorContacts(false)
                      setContactsError('')
                    }}
                    renderInput={(params) => (
                    <TextField
                        onKeyDown={ (event) => { if (event.key === 'Enter') { sendMessage(subject, contacts, message, 'send') }}}
                        error={isErrorContacts}
                        {...params}
                        variant="standard"
                        label={Language['Contacts']}
                        placeholder="contact"
                        onChange={ (e) => {
                          console.log(e.target.value)
                          setIsErrorContacts(false)
                          setContactsError('')
                        }
                        }
                    />
                    )}
                />
                    <FormHelperText id="first_name_error">{contactsError}</FormHelperText>
                </FormControl>
                <p style={{color: 'gray', float: 'right',padding: '0px', margin: '0px', marginTop: '10px'}}>{Language['Show contacts by groups']}<Switch style={{float: 'right'}} {...label} defaultChecked={showByGroup}  onChange={(e) => { setShowByGroup(e.target.checked)}} /></p>



                <FormControl error={isErrorMessage} variant="standard" style={{width: '100%', marginTop: '0px'}}>
                <TextField
                    //onKeyDown={ (event) => { if (event.key === 'Enter') { sendMessage(subject, contacts, message, 'send') }}}
                    error={isErrorMessage}
                    aria-describedby="message_error"
                    style={{width: '100%', marginTop: '0px'}}
                    id="standard-multiline-static"
                    label={Language['Message']}
                    multiline
                    rows={7}
                    variant="standard"
                    value={message}
                    onChange={ (e) => {
                      //calcMessage(e.target.value.length)
                      MessagePricing(e.target.value, e)
                      setMessage(e.target.value)
                      setIsErrorMessage(false)
                      setMessageError('')
                    }
                    }
                />
                    <FormHelperText id="message_error">{messageError}</FormHelperText>
                    <p style={{ marginTop: '0px', textAlign: 'right', color: 'grey'}}> 
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={openPop}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        
                      >
                        <Typography sx={{ p: 1 }} style={{maxWidth:'500px'}}>{Language['Please note that messages containing Arabic characters or special characters will be counted as one message if they are 70 characters or less. Messages without any Arabic characters or special characters will be counted as one message if they are 160 characters or less.']}</Typography>
                      </Popover>
                      <div  style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', flexWrap: 'wrap'}}>

                      <span style={{marginRight: '0px', minWidth: '300px', flex: '1', textAlign: 'start', display: afterBestLength === '' ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', color: '#5a5959' }}> <NotificationAddOutlined style={{marginRight: '5px', color: 'orange'}}/> {afterBestLength}</span> 
                      <span>{arabicMessage}</span>
                      <span style={{marginRight: '20px', width: 'fit-content'}}>{Language['Characters']} {messageLength} </span> 
                      <span style={{marginRight: '0px', width: 'fit-content'}}>{Language['Message']} {messageCalc}  </span> 
                      </div>
                      {/* Cost {price} DA */}
                    </p>
                </FormControl>

                


                <BtnContainer>
                    <Tooltip title='programme the message' >
                        <Button variant="contained" disabled={disabledBtnSchedule}  style={{marginLeft: '10px', fontSize: "small", backgroundColor: '#85a5d6'}} onClick={() => { pre_programmeMessage(subject, contacts, message, 'check') }} > {Language['programme']}   {programmeMessageBtnContent} </Button>
                    </Tooltip>

                    <Tooltip title='Send the message' >
                        <Button variant="contained" disabled={disabledBtnSend} color="secondary" style={{marginLeft: '10px', fontSize: "small", color: 'white', backgroundColor: disabledBtnSend ? colors.darkGray : colors.primary}} onClick={() => { sendMessage(subject, contacts, message, 'send', showByGroup) }} > {Language['send']}  {sendMessageBtnContent}</Button>
                    </Tooltip>
                </BtnContainer>






            </Form>
        </BodyContent>

              {/* //  message programming modal */}
               <Dialog open={open} onClose={handleClosePragrammeMessageModal}>
              <DialogTitle style={{alignContent: 'center'}}> <span>{Language['Pragramme a message']}</span>   </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {Language['Fill the field below then press programme, this action will send your message at the selected time.']}
                </DialogContentText>

                <FormControl error={isErrorTime} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['Select date']}</p>
                    <Input
                      onChange={(e) => {
                        setTime(e.target.value)
                        setIsErrorTime(false)
                        setTimeError('')
                      }}
                      type='date'
                      id=""
                      defaultValue=""
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error">{timeError}</FormHelperText>
                </FormControl>

                <FormControl error={isErrorHour} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['Time']}</p>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    defaultValue={hour}
                    //value={'age'}
                    onChange={ (e) =>{
                        setHour(e.target.value)
                        setIsErrorHour(false)
                        setHourError('')
                    }
                    }
                    >
                      <MenuItem value={"07"}>07:00</MenuItem>
                      <MenuItem value={"08"}>08:00</MenuItem>
                      <MenuItem value={"09"}>09:00</MenuItem>
                      <MenuItem value={"10"}>10:00</MenuItem>
                      <MenuItem value={"11"}>11:00</MenuItem>
                      <MenuItem value={"12"}>12:00</MenuItem>
                      <MenuItem value={"13"}>13:00</MenuItem>
                      <MenuItem value={"14"}>14:00</MenuItem>
                      <MenuItem value={"15"}>15:00</MenuItem>
                      <MenuItem value={"16"}>16:00</MenuItem>
                      <MenuItem value={"17"}>17:00</MenuItem>
                      <MenuItem value={"18"}>18:00</MenuItem>
                      <MenuItem value={"19"}>19:00</MenuItem>
                      <MenuItem value={"20"}>20:00</MenuItem>
                      <MenuItem value={"21"}>21:00</MenuItem>
                      <MenuItem value={"22"}>22:00</MenuItem>
                    </Select>
                    <FormHelperText id="time_error">{hourError}</FormHelperText>
                </FormControl>

                <FormControl error={isErrorRepeted} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['Repeted']}</p>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    //value={'age'}
                    onChange={ (e) =>{
                        setRepeted(e.target.value)
                        setIsErrorRepeted(false)
                        setRepetedError('')
                    }
                    }
                    >
                    <MenuItem value="no-repeat">
                        <em>{Language['None']}</em>
                    </MenuItem>
                    <MenuItem value={"month"}>{Language['monthly']}</MenuItem>
                    <MenuItem value={"year"}>{Language['yearly']}</MenuItem>
                    </Select>
                    <FormHelperText id="time_error">{repetedError}</FormHelperText>
                </FormControl>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClosePragrammeMessageModal}>{Language['Cancel']}</Button>
                <Button disabled={disabledBtnSaveSchedule} onClick={() => programmeMessage(subject, contacts, message, time, repeted, hour, showByGroup)} >{Language[confirmProgrammeMessageBtn]}</Button>
              </DialogActions>
                </Dialog>


                {/* //add user  modal */}
                <Dialog open={openUserModal} onClose={handleCloseUserModal}>
              <DialogTitle style={{alignContent: 'center'}}>{Language['Create New Contact']} <span style={{fontWeight: '600'}}>+</span>  </DialogTitle>
              <DialogContent>
                
                    {
                      [""].map(() => {
                        if(showByGroup){
                          return(
                            <>
                            <DialogContentText style={{marginBottom: '20px'}}>
                            {Language['This is a quick way to create a contact and assemble it in a new group.']}
                            </DialogContentText>
                            <FormControl error={is_error_group} variant="standard" style={{width: '100%', marginTop: '0px', marginBottom: '20px'}}>
                              <InputLabel htmlFor="group">{Language['Group Name']} *</InputLabel>
                              <Input
                                onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}}}
                                onChange={(e) => {
                                  set_add_group(e.target.value);
                                  set_is_error_group(false)
                                  set_group_error('')
                                }}
                                type='text'
                                id="group"
                                defaultValue={add_group}
                                aria-describedby="group_error"
                              />
                              <FormHelperText id="group_error">{group_error}</FormHelperText>
                            </FormControl>
                            <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                              <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                              <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>{Language['Contact']}</span>
                              <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                            </p>
                            </>
                          )
                          
                        }
                      })
                    }

                        
                        <DialogContentText style={{marginBottom: '20px'}}>{Language['fill the field below then press create, this action will create a new contact according to this information.']}</DialogContentText>
               
               
                
                        <FormControl error={is_error_society} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="society">{Language['society']} *</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}}}
                      onChange={(e) => {
                        set_add_society(e.target.value);
                        set_is_error_society(false)
                        set_society_error('')
                      }}
                      type='text'
                      id="society"
                      defaultValue={add_society}
                      aria-describedby="society_error"
                    />
                    <FormHelperText id="society_error">{society_error}</FormHelperText>
                </FormControl>
               
                <FormControl error={is_error_first_name} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="first_name">{Language['First Name']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}}}
                      onChange={(e) => {
                        set_add_first_name(e.target.value);
                        set_is_error_first_name(false)
                        set_first_name_error('')
                      }}
                      type='text'
                      id="first_name"
                      defaultValue={add_first_name}
                      aria-describedby="first_name_error"
                    />
                    <FormHelperText id="first_name_error">{first_name_error}</FormHelperText>
                </FormControl>

                <FormControl error={is_error_family_name} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="family_name">{Language['Family Name']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}}}
                      onChange={(e) => {
                        set_add_family_name(e.target.value);
                        set_is_error_family_name(false)
                        set_family_name_error('')
                      }}
                      type='text'
                      id="family_name"
                      defaultValue={add_family_name}
                      aria-describedby="family_name_error"
                    />
                    <FormHelperText id="family_name_error">{family_name_error}</FormHelperText>
                </FormControl>


                <FormControl error={is_error_phone_number} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="phone_number">{Language['Phone Number']} *</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}}}
                      onChange={(e) => {
                        set_add_phone_number(e.target.value);
                        set_is_error_phone_number(false)
                        set_phone_number_error('')
                      }}
                      type='text'
                      id="phone_number"
                      defaultValue={add_phone_number}
                      aria-describedby="phone_number_error"
                    />
                    <FormHelperText id="phone_number_error">{phone_number_error}</FormHelperText>
                </FormControl>


                <FormControl error={is_error_email} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="email">{Language['email']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}}}
                      onChange={(e) => {
                        set_add_email(e.target.value);
                        set_is_error_email(false)
                        set_email_error('')
                      }}
                      type='text'
                      id={add_email}
                      defaultValue=""
                      aria-describedby="email_error"
                    />
                    <FormHelperText id="email_error">{email_error}</FormHelperText>
                </FormControl>

              


                

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUserModal}>{Language['Cancel']}</Button>
                <Button disabled={disabledCreateContact} onClick={() => create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group, showByGroup)}>{Language[create_contact_btn_content]}</Button>
              </DialogActions>
                </Dialog>



                <Snackbar
                  open={openAlert}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                  {Language[alertMessage] ? Language[alertMessage] : alertMessage}
                  </Alert>
                </Snackbar>
    </Body>
  )
}

